body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.product-card{
  border: solid 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #888888;
  margin: 50px 0px 40px 0px;
  padding: 20px;
  position: relative;
}

.product-card-header{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -40px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;   
}

.product-card-numbers{
  background: #208671;
  border: solid 0px;
  border-radius: 50%;
  color: #ffffff;
  left: -46px;
  line-height: 50px;
  margin: initial;
  position: relative;
  text-align: center;
  top: -40px;
  width: 50px;
}

.product-card-header .product-card-cta-button{
  background: #208671;
  border-color: #208671;
  border: solid 0px;
  border-radius: 5px;
  color: #ffffff!important;
  font-size: 1.0rem;
  font-weight: bold;
  padding: 9px 15px;
  margin: 25px;
  top: 50%;
  text-align: center;
  text-decoration: none!important;
  text-transform: uppercase;
  width: max-content;
  white-space: nowrap;
}

.product-card-cta-button:hover{
  background-color: #208671;
  color: #ffffff!important;
}

.product-card-logo{
  max-width: 30%!important;
}

.product-card h2{
  color: #000000;
  display: block;
  font-size: 1.88rem;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 0px 80px 0px 80px;
  text-align: center;
  text-transform: none;
}

/*The ::after displays a blue underline. So this will keep the line from being shown */
.product-card h2::after{
  display: none;
}

.product-card p{
  margin-left: 10px;
}


@media only screen and (max-width:767px){
  .product-card{
     margin-bottom: 40px;
     margin-left: 3px;
     margin-right: 0px;
     margin-top: 40px;
     padding: 10px 10px 1px 10px;
  }

  .product-card-header{
    margin-left: 0px;
    margin-top: -23px;
    padding: 4px;
    width: 100%;
  }

  .product-card h2{
    display: block;
    font-size: 1.1rem;
    line-height: 25px;
    margin-bottom: 5px;
    margin-top: -3px;
    padding: 0px 0px 0px 0px;
    text-align: center;
 }

 .product-card-header .product-card-cta-button{
    font-size: 0.55rem;
    padding: 7px;
    text-align: center;
  }

  .product-card p{
     font-size: 12px;
     margin-bottom: 0.5rem;
     margin-left: 4px;
  }

  .product-card li,
  .product-card ul,
  .product-card ol{
     font-size: 12px;
  }

  .product-card strong{
     font-size: 12px;
     margin-top: 5px;
  }
  
  .product-card-numbers{
     border-radius: 50%;
     left: -23px;
     line-height: 30px;
     top: -23px;
     width: 30px;
  }
}
.extraPCButton {
  background: #208671;
  border: 1px solid #208671;
  border-bottom: 3px solid #208671;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-size: .75rem;
  line-height: normal;
  padding: 12px 24px;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-transform: uppercase;
  text-decoration: none!important;
  -webkit-appearance: button;
}
.productCardHref {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.product-card a.productCardHref{
  text-decoration: none!important;
}